// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../../../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-datenschutz-mdx": () => import("./../../../src/pages/datenschutz.mdx" /* webpackChunkName: "component---src-pages-datenschutz-mdx" */),
  "component---src-pages-fertiggaragen-mdx": () => import("./../../../src/pages/fertiggaragen.mdx" /* webpackChunkName: "component---src-pages-fertiggaragen-mdx" */),
  "component---src-pages-garagen-nach-mass-mdx": () => import("./../../../src/pages/garagen-nach-mass.mdx" /* webpackChunkName: "component---src-pages-garagen-nach-mass-mdx" */),
  "component---src-pages-impressum-mdx": () => import("./../../../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */),
  "component---src-pages-index-mdx": () => import("./../../../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-leistung-mdx": () => import("./../../../src/pages/leistung.mdx" /* webpackChunkName: "component---src-pages-leistung-mdx" */),
  "component---src-pages-unternehmen-mdx": () => import("./../../../src/pages/unternehmen.mdx" /* webpackChunkName: "component---src-pages-unternehmen-mdx" */)
}

